import React, {useEffect} from "react"
import Header from "../../components/header"
import Trade from "../../components/trade"
import { FaTrophy } from 'react-icons/fa'
import teamsData from "../../../static/data/teams.json"
import tradesData from "../../../static/data/trades.json"
import { Chart } from 'chart.js'
import {Helmet} from "react-helmet"

function Page({teams}) {
  const season = "2021";
  const teamNames = teamsData.map((team) => team.seasons[season].name);
  const teamColors = teamsData.map((team) => team.color);
  const trades = tradesData[0][season].trades;

  useEffect(() => {
    const teams = teamsData;    
    
    new Chart(document.getElementById("chartPF"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].pointsFor),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsPossible),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPercPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => (
            (team.seasons[season].pointsFor / team.seasons[season].pointsPossible) * 100).toFixed(2)
          ),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPA"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsAgainst),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartAvgScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].avgScore),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartTrades"), {
        type: 'horizontalBar',
        data: {
          datasets: [{
              data: teams.map((team) => team.seasons[season].tradeCount),
              backgroundColor: teamColors,
          }],
  
          labels: teamNames
        },
        options: {
          legend: {
            display: false
          }
        }
      });

    // doesn't pull from array
    new Chart(document.getElementById("chartHighestScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: [
            '185.30',
            '183.08',
            '182.24',
            '178.94',
            '176.97',
            '175.88',
            '171.40',
            '168.52',
            '165.84',
            '165.00'
          ],
          backgroundColor: [
            teamColors[0],
            teamColors[7],
            teamColors[7],
            teamColors[7],
            teamColors[0],
            teamColors[7],
            teamColors[1],
            teamColors[7],
            teamColors[3],
            teamColors[3]
          ],
        }],
        labels: [
          teamNames[0] + ' - Week 11',
          teamNames[7] + ' - Week 4',
          teamNames[7] + ' - Week 5',
          teamNames[7] + ' - Week 16',
          teamNames[0] + ' - Week 4',
          teamNames[7] + ' - Week 11',
          teamNames[1] + ' - Week 2',
          teamNames[7] + ' - Week 2',
          teamNames[3] + ' - Week 5',
          teamNames[3] + ' - Week 10'
        ]
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartAboveAvgPerc"), {
      type: 'polarArea',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].aboveAvgPerc),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    new Chart(document.getElementById("chartWeeklyPlaces"), {
      type: 'line',
      data: {
        labels: [
          'Week 1',
          'Week 2',
          'Week 3',
          'Week 4',
          'Week 5',
          'Week 6',
          'Week 7',
          'Week 8',
          'Week 9',
          'Week 10',
          'Week 11',
          'Week 12',
          'Week 13',
          'Week 14',
          'Week 15',
          'Week 16',
          'Week 17'
        ],
        datasets: 
          teams.map((team,idx) => (
            {
              label: team.seasons[season].name,
              data: team.seasons[season].weeklyPlaces,
              fill: false,
              borderColor: team.color,
              backgroundColor: team.color,
              borderCapStyle: 'square'
            }
          ))
      },
      options: {
        legend: {
          display: true,
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              reverse: true
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    new Chart(document.getElementById("chartOriginalTeam"), {
      type: 'polarArea',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].originalTeamPerc),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    new Chart(document.getElementById("chartFaabSpent"), {
      type: 'doughnut',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].faabSpent),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    new Chart(document.getElementById("chartTier1"),{
      type:"horizontalBar",
      data:{
        labels: teamNames,
        datasets:[
          {
            label: "QB",
            data: teams.map((team) => team.seasons[season].tier1_QB.length),
            backgroundColor: "#C62828"
          },
          {
            label: "RB",
            data: teams.map((team) => team.seasons[season].tier1_RB.length),
            backgroundColor: "#2E7D32"
          },
          {
            label: "WR",
            data: teams.map((team) => team.seasons[season].tier1_WR.length),
            backgroundColor: "#1565C0"
          },
          {
            label: "TE",
            data: teams.map((team) => team.seasons[season].tier1_TE.length),
            backgroundColor: "#F9A825"
          },
          {
            label: "D/ST",
            data: teams.map((team) => team.seasons[season].tier1_DST.length),
            backgroundColor: "#6A1B9A"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    });
  }, [teams]) 

  return (
    <React.Fragment>
      <Helmet>
        <title>{season} Season Recap | Chode Dynasty</title>
      </Helmet>
      
      <Header />
      <main>
        <div className="row">
          <div className="inner">
            <h1>{season} Season Recap</h1>
            
            <div className="cols-2">
              <div>
                <h2>Final Standings</h2>
                <ol className="standings">
                  <li>
                    <span className="label">1<sup>st</sup></span> Take a poo leave a poo (10-5)
                    <div className="trophy place-1"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">2<sup>nd</sup></span> Tua Tuggin'onthelow'a (10-5)
                    <div className="trophy place-2"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">3<sup>rd</sup></span> Ckbenz (9-6)
                    <div className="trophy place-3"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">4<sup>th</sup></span> Chodemissioner (10-5)
                  </li>
                  
                  <li>
                    <span className="label">5<sup>th</sup></span> Human Poop (9-6)
                  </li>
                  
                  <li>
                    <span className="label">6<sup>th</sup></span> Tutu Hot 4U (8-7)
                  </li>
                  
                  <li>
                    <span className="label">7<sup>th</sup></span> Mahomebois (7-8)
                  </li>
                  
                  <li>
                    <span className="label">8<sup>th</sup></span> Jiss Pugs (6-9)
                  </li>
                  
                  <li>
                    <span className="label">9<sup>th</sup></span> frankakatank (4-11)
                  </li>
                  
                  <li>
                    <span className="label">10<sup>th</sup></span> jhoffman (2-13)
                  </li>
                </ol>
              </div>

              <div>
                <h2>Champion Roster</h2>
                <table className="styled champ">
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Player</th>
                      <th>Points</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>QB</td>
                      <td>Justin Herbert (LAC)</td>
                      <td>21.68</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>Ezekiel Elliott (DAL)</td>
                      <td>3.50</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>Austin Ekeler (LAC)</td>
                      <td>18.70</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>Deebo Samuel (SF)</td>
                      <td>15.70</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>Tee Higgins (CIN)</td>
                      <td>7.70</td>
                    </tr>
                    <tr>
                      <td>TE</td>
                      <td>Travis Kelce (KC)</td>
                      <td>10.90</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>Amon-Ra St. Brown (DET)</td>
                      <td>31.40</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>Diontae Johnson (PIT)</td>
                      <td>13.10</td>
                    </tr>
                    <tr>
                      <td>DST</td>
                      <td>New England Patriots</td>
                      <td>13.00</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td><strong>135.68</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>

        <div id="awards" className="row">
          <div className="inner">
            <h2>Awards</h2>
            <div className="cols-2">
              <div>
                <h3><span role="img" aria-label="rocket">🚀</span> Highest Scorer</h3>
                <h4>Take a poo leave a poo</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="pile of poo">💩</span>Lowest Scorer</h3>
                <h4>frankakatank</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="four-leaf clover">🍀</span>Best Luck</h3>
                <div className="desc">(least points against)</div>
                <h4>Human Poop</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="face with head-bandage">🤕</span>Worst Luck</h3>
                <div className="desc">(most points against)</div>
                <h4>Chodemissioner</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="fire">🔥</span>Best Manager</h3>
                <div className="desc">(highest percentage of possible points scored)</div>
                <h4>Tua Tuggin'onthelow'a</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="thinking face">🤔</span>Worst Manager</h3>
                <div className="desc">(lowest percentage of possible points scored)</div>
                <h4>Jiss Pugs</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="increasing chart">📈</span>Most Improved</h3>
                <div className="desc">(biggest increase from last season in points for)</div>
                <h4>Take a poo leave a poo (+346.08)</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="decreasing chart">📉</span>Least Improved </h3>
                <div className="desc">(biggest decrease from last season in points for)</div>
                <h4>Mahomebois (-188.29)</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="check mark">✔️</span>Most Consistent Scorer</h3>
                <h4>Jiss Pugs</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="cross mark">❌</span>Least Consistent Scorer </h3>
                <h4>Mahomebois</h4>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="inner">
            <h2>Total Points For</h2>
            <canvas id="chartPF"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Possible</h2>
            <canvas id="chartPP"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Possible Points Scored</h2>
            <canvas id="chartPercPP"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Against</h2>
            <canvas id="chartPA"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Team Average Score</h2>
            <canvas id="chartAvgScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Games with Above Average Score</h2>
            <div className="chart-container">
              <canvas id="chartAboveAvgPerc"></canvas>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Highest Scores</h2>
            <canvas id="chartHighestScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Finishes</h2>
            <canvas id="chartWeeklyPlaces"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Percentage of Startup Draft Team</h2>
            <div className="chart-desc">(% of original team remaining)</div>
            <div className="chart-container">
              <canvas id="chartOriginalTeam"></canvas>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>FAAB Spent (out of $200)</h2>
            <div className="chart-container">
              <canvas id="chartFaabSpent"></canvas>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Top Scoring Players by Position</h2>
            
            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Quarterback</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>J. Allen</td>
                      <td>469.9</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>J. Herbert</td>
                      <td>457.3</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>T. Brady</td>
                      <td>456.7</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>P. Mahomes</td>
                      <td>428.2</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>M. Stafford</td>
                      <td>406.7</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>A. Rodgers</td>
                      <td>403.8</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>D. Prescott</td>
                      <td>382.1</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>J. Burrow</td>
                      <td>380.2</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>K. Cousins</td>
                      <td>355.8</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>K. Murray</td>
                      <td>335.5</td>
                      <td>Tutu Hot 4U</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>J. Hurts</td>
                      <td>335.2</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>R. Tannehill</td>
                      <td>304.1</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Running Back</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>J. Taylor</td>
                      <td>349.1</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>A. Ekeler</td>
                      <td>304.8</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>N. Harris</td>
                      <td>268.2</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>J. Mixon</td>
                      <td>265.6</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>J. Conner</td>
                      <td>238.7</td>
                      <td>Tutu Hot 4U</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>E. Elliott</td>
                      <td>229.1</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>L. Fournette</td>
                      <td>220.8</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>A. Kamara</td>
                      <td>212.7</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>C. Patterson</td>
                      <td>206.6</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>N. Chubb</td>
                      <td>204.8</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>A. Gibson</td>
                      <td>203.6</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>A. Jones</td>
                      <td>201</td>
                      <td>Tutu Hot 4U</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Wide Receiver</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>C. Kupp</td>
                      <td>368.5</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>D. Samuel</td>
                      <td>300</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>D. Adams</td>
                      <td>284.3</td>
                      <td>Tutu Hot 4U</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>J. Jefferson</td>
                      <td>275.4</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>J. Chase</td>
                      <td>265.9</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>T. Hill</td>
                      <td>242</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>S. Diggs</td>
                      <td>237.5</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>M. Evans</td>
                      <td>228.5</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>D. Johnson</td>
                      <td>218.9</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>M. Williams</td>
                      <td>208.6</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>D. Metcalf</td>
                      <td>207.3</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>H. Renfrow</td>
                      <td>207.1</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Tight End</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>M. Andrews</td>
                      <td>256.4</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>T. Kelce</td>
                      <td>217.3</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>D. Schultz</td>
                      <td>171.3</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>G. Kittle</td>
                      <td>163.5</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>K. Pitts</td>
                      <td>147.1</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>D. Knox</td>
                      <td>146.6</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>R. Gronkowski</td>
                      <td>143.7</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Z. Ertz</td>
                      <td>143.7</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>H. Henry</td>
                      <td>139.3</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>D. Goedert</td>
                      <td>136</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>M. Gesicki</td>
                      <td>131.5</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>N. Fant</td>
                      <td>128</td>
                      <td>Tutu Hot 4U</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Defense / Special Teams</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Cowboys</td>
                      <td>177</td>
                      <td>Tua Tuggin'onthelow'a</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Bills</td>
                      <td>164</td>
                      <td>Tutu Hot 4U</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Patriots</td>
                      <td>161</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Dolphins</td>
                      <td>155</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Saints</td>
                      <td>147</td>
                      <td>Jiss Pugs</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Buccaneers</td>
                      <td>140</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>49ers</td>
                      <td>139</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Cardinals</td>
                      <td>137</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Colts</td>
                      <td>135</td>
                      <td>Mahomebois</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Titans</td>
                      <td>119</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Rams</td>
                      <td>118</td>
                      <td>Tutu Hot 4U</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Chiefs</td>
                      <td>117</td>
                      <td>frankakatank</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Tier 1 Player Management</h2>
            <div className="chart-desc">(how many tier 1 players each team had)</div>
            <canvas id="chartTier1"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Reward Winners</h2>
            <table className="styled weekly-reward">
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Title</th>
                  <th>Challenge</th>
                  <th>Winner</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ready Set Go</td>
                  <td>Team with the highest score</td>
                  <td>Mahomebois - 154.38</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Hero to Zero</td>
                  <td>Team with the biggest drop in score from Week 1 to Week 2</td>
                  <td>Mahomebois - 72.76 drop in score</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Air It Out</td>
                  <td>Team with the QB with the longest pass</td>
                  <td>Tua Tuggin'onthelow'a - Matthew Stafford had a 51.8 yard pass</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Gotta Catch Em All</td>
                  <td>Team with the WR with the most receptions</td>
                  <td>Chodemissioner - Tyreek Hill (11)</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Runt of the Litter</td>
                  <td>Winning team with the lowest scoring starter</td>
                  <td>Take a poo leave a poo - Saquon Barkley (0.90 pts)</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Got There</td>
                  <td>Team that beats its opponent by the smallest margin of victory</td>
                  <td>Chodemissioner - 5.56 pts</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Halftime</td>
                  <td>Team with the most total points after 7 weeks</td>
                  <td>Take a poo leave a poo - 1040.38 pts</td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Blackjack</td>
                  <td>Team with a starter closest to 21 points without going over</td>
                  <td>Tua Tuggin'onthelow'a - Cooper Kupp (21 pts)</td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>The Purge</td>
                  <td>Subtract last week's score from this weeks, the team with the highest remaining score wins</td>
                  <td>jhoffman - 40.34 pts</td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Right On Target</td>
                  <td>Team closest to their projected score (over OR under)</td>
                  <td>Human Poop - 0.08 pts off of projected</td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>At Least You Tried</td>
                  <td>Highest scoring losing team</td>
                  <td>Ckbenz - 152.88 pts</td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>Magic Legs</td>
                  <td>Team with the RB with the most rushing yards</td>
                  <td>Jiss Pugs - Joe Mixon (165 yards)</td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>End Zone</td>
                  <td>Team with the most offensive touchdowns scored</td>
                  <td>GingerBeard77 - 8 TDs</td>
                </tr>

                <tr>
                  <td>15</td>
                  <td>Consolation Prize</td>
                  <td>Team with the most points for on the season that didn't make the playoffs</td>
                  <td>GingerBeard77 - 1607.69 pts</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id="trades" className="row">
          <div className="inner">
            <h2>Trades</h2>

            <h3>Total Trades - {trades.length}</h3>
            <canvas id="chartTrades"></canvas>

            <h3>Trade Details</h3>
            <div id="trades-details">
              {trades.map((data,id)=>{
                return <Trade key={id} data={data} teamNames={teamNames} />
              })}
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Page;